<template>
  <div class="king">
    <div class="super">
      <div class="holder">
        <Header />
        <!-- <PromoGif></PromoGif> -->
        <!-- Use the second margin after you remove the letigation blink stuff old margin is 6rem -->
        <div class="blinker-div">
          <h4 @click="moveToLitigation()" class="blinking-text">FREE LITIGATION: CLICK HERE</h4>
        </div>

        <div class="first-hero">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 hero-row">
                <div class="first-hero-text">
                  <h1>Property Financing and Management For Every African.</h1>
                  <p>
                    Shelta makes living sustainable, affordable, accessible,
                    peaceful and rewarding for all.
                  </p>
                  <div class="first-hero-bottons">
                    <button class="btn" @click="createAccountHero">
                      <router-link to="/signup">
                        Create a free account
                      </router-link>
                    </button>
                    <button class="btn">
                      <router-link to="contactus">
                        Contact Sales
                      </router-link>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-none d-md-block">
                <div>
                  <img src="../assets/home/firsthero.svg" alt="home" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- First Hero section ends -->

        <!-- Property Financing section begins -->
        <div class="prop-financing">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="finnacing-holder-left">
                  <h6>PROPERTY FINANCING</h6>
                  <h4>Everything you need to get paid on time.</h4>
                  <!-- <p>
                    As a landlord, you should not wait to get your deserved rent
                    nor beg to handle your properties with care.
                  </p>
                  <p>
                    Shelta SAFE product offers a simple and secure way for
                    landlords to get paid without delay.
                  </p>
                  <p>
                    Also, we manage your properties that it retains its value
                    and protect you and yours from any act of legal claim from
                    tenants.
                  </p> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="finnacing-holder-right">
                  <p>
                    Living in your desired environment or getting your dream
                    house should not be difficult.
                  </p>
                  <p>
                    With SAFE flexible payment by Shelta, you can rent or buy
                    properties in any location of your choice and also benefits
                    from our renewable energy solutions that takes care of your
                    lightning needs.
                  </p>
                </div>
              </div>
            </div>
            <div class="three-services">
              <div class="row">
                <div class="col-md-4">
                  <div class="service-holder" @click="routeme('rent')">
                    <small><small class="rain-drop">1</small></small>
                    <span>Rent Financing</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="service-holder" @click="routeme('prop')">
                    <small><small class="rain-drop">2</small></small>
                    <span>Property Financing</span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="service-holder" @click="routeme('safe')">
                    <small><small class="rain-drop">3</small></small>
                    <span>Renewable Energy</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Property Financing section ends -->

        <!-- Property Mangement section begins -->
        <hr style="border-top: 1px solid #f9f9f9;" />
        <div class="prop-management">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="propmanagement-holder-left">
                  <h6>PROPERTY MANAGEMENT</h6>
                  <h4>Everything you need to enjoy sustainable living.</h4>
                  <p class="d-none d-md-block">
                    As a landlord, you should not wait to get your deserved rent
                    nor beg to handle your properties with care.
                  </p>
                  <p class="d-none d-md-block">
                    Shelta SAFE product offers a simple and secure way for
                    landlords to get paid without delay.
                  </p>
                  <p class="d-none d-md-block">
                    Also, we manage your properties that it retains its value
                    and protect you and yours from any act of legal claim from
                    tenants.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="propmanagement-holder-right">
                  <div class="box-holder pm">
                    <div>
                      <img src="../assets/home/pmicon.svg" alt="key" />
                    </div>
                    <small>Property Management</small>
                    <p class=" d-block d-sm-none d-none d-sm-block d-md-none">
                      As a landlord, you should not wait to get your deserved
                      rent nor beg to handle your properties with care.
                    </p>
                  </div>
                  <div class="box-holder fm">
                    <div>
                      <img src="../assets/home/fmicon.svg" alt="prop" />
                    </div>
                    <small>Facility Management</small>
                    <p class=" d-block d-sm-none d-none d-sm-block d-md-none">
                      We have a team of professionals dedicated to supporting
                      you and yours. We ensure the functionality, comfort,
                      safety, sustainability and efficiency of your properties.
                    </p>
                  </div>
                  <div class="box-holder lc">
                    <div>
                      <img src="../assets/home/lcicon.svg" alt="suit" />
                    </div>
                    <small>Litigation Cover</small>
                    <p class=" d-block d-sm-none d-none d-sm-block d-md-none">
                      Also, we manage your properties that it retains its value
                      and protect you and yours from any act of legal claim from
                      tenants.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Property Management section ends -->

        <HomePart />
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import HomePart from "../components/HomePart.vue";
import PromoGif from "../components/PromoGif.vue";
import $ from "jquery";
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "Landing",
  components: {
    Header,
    FooterMain,
    HomePart,
    PromoGif
  },
  data() {
    return {
      theClient: "landlord"
    };
  },
  mounted() {
    this.checkLogger();
  },
  methods: {
    moveToLitigation() {
      this.$router
          .push({
            path: `/free-litigation`
          })
          .catch(() => {});
    },
    checkLogger() {
      Api.postRequest(`logcounter`, {})
        .then(res => {
          // console.log("Who reads", res);
        })
        .catch(err => {
          console.log(err);
          this.loader = false;
        });
    },
    routeme(item) {
      if (item == "rent") {
        this.$router
          .push({
            path: `/tenant`
          })
          .catch(() => {});
      }
      if (item == "prop") {
        this.$router
          .push({
            path: `/landlord`
          })
          .catch(() => {});
      }
      if (item == "safe") {
        this.$router
          .push({
            path: `/safe`
          })
          .catch(() => {});
      }
    },
    moveToSure() {
      this.$router
        .push({
          path: `/sure`
        })
        .catch(() => {});
    },
    createAccountHero() {
      this.$gtag.event("first-hero-create-account-clicked", {
        event_category: "first hero create account click",
        event_label: "Create a free account button clicked",
        value: 1
      });
    },
    contactSales() {
      this.$gtag.event("contact-sales-clicked", {
        event_category: "contact sales click",
        event_label: "Contact sales button clicked",
        value: 1
      });
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
$sheltaBlack: #000a2f;
$primary: #0033ea;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;
$listing-bg: #64abb2;
$offGray: #555c74;
$bg: #f9f9ff;

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

a {
  color: inherit;
}
a:hover {
  color: inherit;
  text-decoration: inherit;
}
.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  position: relative;
  background: $whiteText;
  position: relative;
}

// First hero section begins
.first-hero {
  background-color: $bg;
  // User the second margin after you remove the letigation stuff
  margin-top: 0rem;
  // margin-top: 6rem;
  // padding-top: 1rem;
  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 125%;
    color: $sheltaBlack;
    text-align: left;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $offGray;
    text-align: left;
  }
  img {
    width: 100%;
  }
}
.hero-row {
  padding: 0;
}
.first-hero-text {
  padding-left: 77px;
  padding-top: 7rem;
}

@media only screen and (max-width: 599px) {
  .hero-row {
    padding: 0;
  }
  .first-hero-text {
    padding-left: 0px;
    // padding-top: 8rem;
    // height: 785px;
    // height: 650px;
    height: 685px;
    // padding: 2rem 15px 0;
    padding: 0rem 15px 0;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.75) 82.81%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("../assets/home/firsthero-mobile.svg") no-repeat center center;
  }
  .first-hero {
    h1 {
      font-size: 40px;
      // padding-top: 40px;
      padding-top: 5rem;
      text-align: left;

    }
    p {
      padding-top: 20px;
      text-align: center;
      font-size: 25px;
      text-align: left;
    }
  }
}

.first-hero-bottons {
  display: flex;
  margin-top: 50px;
  button {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    height: 56px;
    box-shadow: none;
    outline: 0;
    margin-top: 2rem;
  }
  button:first-child {
    background-color: $primary;
    color: $whiteText;
    width: 183px;
    margin-right: 20px;
  }
  button:nth-child(2) {
    width: 128px;
    background-color: $whiteText;
    color: $primary;
    border: 1px solid $primary;
  }
}

@media only screen and (max-width: 599px) {
  .first-hero-bottons {
    // display: block;
    // margin-top: 90px;
    // margin-bottom: 20px;
    padding-bottom: 20px;
    margin-top: 15px;
    button {
      width: 100% !important;
    }
    button:first-child {
      margin-right: 10px !important;
    }
  }
}
// First hero section ends

// Property Financing section begins
.prop-financing {
  padding-top: 60px;
  padding-left: 77px;
  //   padding-right: 55px;
  padding-right: 77px;
}
.finnacing-holder-left {
  text-align: left;
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 125%;
    color: $primary;
  }
  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    color: $sheltaBlack;
  }
}
.finnacing-holder-right {
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $offGray;
    padding-bottom: 10px;
    text-align: left;
  }
}

@media only screen and (max-width: 599px) {
  .finnacing-holder-left {
    text-align: center;
    h4 {
      font-size: 22px;
    }
    h6 {
      text-align: center;
    }
  }
  .prop-financing {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.three-services {
  padding-top: 41px;
  padding-bottom: 50px;
}
.service-holder {
  small:first-child {
    display: inline-block;
    transform: rotate(130deg);
    border: 1px solid $offGray;
    width: 25px;
    height: 25px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    margin-right: 12px;
    cursor: pointer;
    small {
      border: none;
      text-align: center;
      padding: 2px;
      padding-left: 4px;
      padding-top: 5px;
      cursor: pointer;
    }
  }
  .rain-drop {
    transform: rotate(230deg) !important;
  }
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: $offGray;
    cursor: pointer;
  }
}

@media only screen and (max-width: 599px) {
  .service-holder {
    text-align: left;
    margin-bottom: 14px;
  }
  .three-services {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
// Property financing section ends

// Property Management section begins
.prop-management {
  padding-top: 60px;
  padding-left: 77px;
  //   padding-right: 55px;
  padding-right: 77px;
}
.propmanagement-holder-left {
  text-align: left;
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 125%;
    color: $primary;
  }
  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    color: $sheltaBlack;
    // text-align: left;
  }
}
.propmanagement-holder-right {
  display: flex;
  justify-content: center;
}

.box-holder {
  width: 185px;
  height: 166px;
  border-radius: 10px;
  padding: 20px 20px;
  div {
    text-align: right;
  }
  small {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: left;
    display: flex;
    padding-top: 20px;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $offGray;
    text-align: left;
  }
}
.pm {
  // background: #fff7e6;
  background: #fbf1cc;
}
.fm {
  background: #f1ccfb;
  margin: 7rem 2rem 0 2rem;
}
.lc {
  background: #ccfbd9;
  small {
    padding-right: 10px;
  }
}

.blinker-div {
  width: fit-content;
    display: flex;
    justify-content: center;
    color: #fff;
    margin: auto;
    background: #0033ea;
    h4 {
      font-family: Lato;
      padding: 10px;
      margin-bottom: 0;
      cursor: pointer;
      font-size: 22px;
    }
}

.blinking-text {
    animation: blink 1.5s infinite;
    // position: absolute;
    // top: 40px;
    margin-top: 7rem;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

@media only screen and (max-width: 599px) {
  .box-holder {
    height: fit-content;
    div {
      text-align: left;
    }
  }
  .propmanagement-holder-left {
    text-align: center;
    h4 {
      font-size: 22px;
    }
  }
  .prop-management {
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .propmanagement-holder-right {
    display: block;
  }
  .pm,
  .fm,
  .lc {
    margin: 1rem auto;
    width: 100%;
    // margin: 2rem 0rem 1rem 0rem;
  }
}
// Property management section ends
</style>
