// import Vue from "vue";
import axios from "axios";
import vue from "vue";
// import httpClient from "core/common";
// import { HttpHeaders } from "./http-common";
// window.axios = require("axios");
// const API_URL = "https//mmuoka.com/elbox_back/public/api/";

// const BASE_URL = "https://myshelta.com/shelterbackend/public/api";
const BASE_URL = "https://myshelta.com/Testbackend/public/api";

// const BASE_URL2 = "https://epronserver.onrender.com";

let getDataDetails = {};
let getUrlDetails = {};
let getPageData = {};
let getProjectDetails = {};
let getUserOption = "";
let getPaymentBreakdown: never[] = [];
// let getDataPin = "";

export class Service {
  token: string | null;
  $router: any;
  // const = getDataDetails;
  constructor() {
    // this.userProfile();
    // this.getDataDetails = {};
    // this.getDataDetails;
    this.token = this.getToken();
    // this.header = new HttpHeaders({
    //   "Content-Type": "application/json",
    //   Accept: "application/json",
    //   Authorization: "Bearer " + this.token,
    // });
    // this.loginHeader = new HttpHeaders({
    //   "Content-Type": "application/json",
    //   Accept: "application/json",
    // });
  }

  // getRequest2(url) {
  //   return axios.get(`${BASE_URL2}/${url}`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: "Bearer " + this.token
  //     }
  //   });
  // }

  getRequest(url: string) {
    return axios.get(`${BASE_URL}/${url}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
        shelta: "web"
      }
    });
  }

  userProfile() {
    if (localStorage.getItem("shelta-user")) {
      const id = JSON.parse(localStorage.getItem("shelta-user") || "{}").id;
      this.getRequest(`getusercompleteprofile/${id}`)
        .then(res => {
          // console.log("response for user profile", res);
          if (res.status == 401) {
            window.localStorage.clear();
            window.location.href = "http://localhost:8080/login";
          }
        })
        .catch(err => {
          console.log("err:", err);
        });
    }
  }
  // user() {
  //   if (localStorage.getItem("shelta-user")) {
  //     const id = JSON.parse(localStorage.getItem("shelta-user") || "{}").id;
  //     this.getRequest(`getuserbyid/${id}`)
  //       .then(res => {
  //         // console.log("response for user profile", res);
  //         if (res.status == 401) {
  //           window.localStorage.clear();
  //           window.location.href = "https://myshelta.com/login";
  //         }
  //       })
  //       .catch(err => {
  //         console.log("err:", err);
  //       });
  //   }
  // }

  getRequestNoAuth2(url: any) {
    return axios.get(`/${url}`);
  }

  getRequestNoAuth(url: any) {
    return axios.get(`${BASE_URL}/${url}`);
  }

  postRequest(url: any, data: any) {
    return axios.post(`${BASE_URL}/${url}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
        shelta: "web"
      }
    });
  }

  postRequestNoAuth(url: any, data: any) {
    return axios.post(`${BASE_URL}/${url}`, data);
  }

  setToken(token: string) {
    localStorage.setItem("shelta", token);
  }

  getToken() {
    return localStorage.getItem("shelta");
  }

  getPropertyDetails() {
    // console.log("prop details", getDataDetails);
    return getDataDetails;
  }

  pushPropertyDetails(data: {}) {
    getDataDetails = data;
  }
  pushProjectDetails(data: {}) {
    getProjectDetails = data;
  }

  // For pagination
  pushPageURL(url1: {}) {
    getUrlDetails = url1;
  }

  // For pagination
  getPageURL() {
    // console.log("Page url", getUrlDetails);
    return getUrlDetails;
  }

  // push data to be paginated
  pushPageData(data: {}) {
    getPageData = data;
    // console.log("Pushed datatat", getPageData);
  }

  // Get the paginated data
  getPaginatedData() {
    // console.log("Page info data", getPageData);
    return getPageData;
  }

  getProjectDetails() {
    // console.log("Page info data", getPageData);
    return getProjectDetails;
  }

  pushUserOption(data: string) {
    // console.log("user option", data);
    getUserOption = data;
  }
  getUserOption() {
    // console.log("user option", getUserOption);
    return getUserOption;
  }

  pushPaymentBreakdown(data: []) {
    // console.log("Payment Breakdown", data);
    getPaymentBreakdown = data;
  }

  getPaymentBreakdown() {
    // console.log("Page info data", getPageData);
    return getPaymentBreakdown;
  }

  // getSubscription();

  // getPropertyPin() {
  //   // console.log("Proppppp pin", getDataPin);
  //   return getDataPin;
  // }

  // pushPropertyPin(pin) {
  //   getDataPin = pin;
  //   // console.log("service data", getDataPin);
  // }

  successNotification(msg: string) {
    return (
      '<div class="alert alert-success" role="alert"></div>' + msg + "<div>"
    );
  }

  errorNotification(msg: string) {
    return '<div class="alert alert-danger" role="alert">' + msg + "<div>";
  }
  warningNotification(msg: string) {
    return '<div class="alert alert-warning" role="alert">' + msg + "<div>";
  }

  // return this.http
  //     .get(`${this.baseUrl + getUrl}`, { headers: this.header })
  //     .toPromise();
}
